import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Section from "../../components/section";
import Banner from "../../components/banner";
import FunctionalityShowcase from '../../components/functionality-showcase';

const ProductTour = (props) => {
  return <Layout businessUp test>
    <SEO title={props.intl.formatMessage({id: "Product Tour"})} description="" location={props.location} />

    <Banner 
        className="banner"  
        filename="clients-banner.png"
        title={props.intl.formatMessage({id: "Product Tour"})} 
        subtitle={props.intl.formatMessage({id: "Explora las funcionalidades de la plataforma"})}
    />

    <Section title={props.intl.formatMessage({id: "Product Tour"})}>
        <FunctionalityShowcase />
    </Section>
  </Layout>
}

export default injectIntl(ProductTour);